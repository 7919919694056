import Activity from 'react-feather/dist/icons/activity';
import AlertCircle from 'react-feather/dist/icons/alert-circle';
import AlertTriangle from 'react-feather/dist/icons/alert-triangle';
import AlertOctagon from 'react-feather/dist/icons/alert-octagon';
import AlignCenter from 'react-feather/dist/icons/align-center';
import AlignLeft from 'react-feather/dist/icons/align-left';
import AlignRight from 'react-feather/dist/icons/align-right';
import Archive from 'react-feather/dist/icons/archive';
import ArrowDownRight from 'react-feather/dist/icons/arrow-down-right';
import ArrowLeft from 'react-feather/dist/icons/arrow-left';
import ArrowLeftCircle from 'react-feather/dist/icons/arrow-left-circle';
import ArrowRight from 'react-feather/dist/icons/arrow-right';
import ArrowRightCircle from 'react-feather/dist/icons/arrow-right-circle';
import ArrowUpRight from 'react-feather/dist/icons/arrow-up-right';
import Award from 'react-feather/dist/icons/award';
import BatteryCharging from 'react-feather/dist/icons/battery-charging';
import BellOff from 'react-feather/dist/icons/bell-off';
import Bell from 'react-feather/dist/icons/bell';
import BookOpen from 'react-feather/dist/icons/book-open';
import Calendar from 'react-feather/dist/icons/calendar';
import Camera from 'react-feather/dist/icons/camera';
import CameraOff from 'react-feather/dist/icons/camera-off';
import Check from 'react-feather/dist/icons/check';
import CheckCircle from 'react-feather/dist/icons/check-circle';
import CheckSquare from 'react-feather/dist/icons/check-square';
import ChevronDown from 'react-feather/dist/icons/chevron-down';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import ChevronsLeft from 'react-feather/dist/icons/chevrons-left';
import ChevronsRight from 'react-feather/dist/icons/chevrons-right';
import ChevronUp from 'react-feather/dist/icons/chevron-up';
import Circle from 'react-feather/dist/icons/circle';
import Clipboard from 'react-feather/dist/icons/clipboard';
import Clock from 'react-feather/dist/icons/clock';
import Code from 'react-feather/dist/icons/code';
import Coffee from 'react-feather/dist/icons/coffee';
import Copy from 'react-feather/dist/icons/copy';
import CornerDownRight from 'react-feather/dist/icons/corner-down-right';
import Crop from 'react-feather/dist/icons/crop';
import Crosshair from 'react-feather/dist/icons/crosshair';
import Download from 'react-feather/dist/icons/download';
import DollarSign from 'react-feather/dist/icons/dollar-sign';
import Edit from 'react-feather/dist/icons/edit';
import ExternalLink from 'react-feather/dist/icons/external-link';
import Eye from 'react-feather/dist/icons/eye';
import EyeOff from 'react-feather/dist/icons/eye-off';
import FastForward from 'react-feather/dist/icons/fast-forward';
import Feather from 'react-feather/dist/icons/feather';
import File from 'react-feather/dist/icons/file';
import FilePlus from 'react-feather/dist/icons/file-plus';
import FileText from 'react-feather/dist/icons/file-text';
import Film from 'react-feather/dist/icons/film';
import Filter from 'react-feather/dist/icons/filter';
import Flag from 'react-feather/dist/icons/flag';
import Folder from 'react-feather/dist/icons/folder';
import Globe from 'react-feather/dist/icons/globe';
import Gift from 'react-feather/dist/icons/gift';
import Hash from 'react-feather/dist/icons/hash';
import Headphones from 'react-feather/dist/icons/headphones';
import Heart from 'react-feather/dist/icons/heart';
import HelpCircle from 'react-feather/dist/icons/help-circle';
import Home from 'react-feather/dist/icons/home';
import Image from 'react-feather/dist/icons/image';
import Info from 'react-feather/dist/icons/info';
import Layers from 'react-feather/dist/icons/layers';
import Layout from 'react-feather/dist/icons/layout';
import List from 'react-feather/dist/icons/list';
import Loader from 'react-feather/dist/icons/loader';
import Lock from 'react-feather/dist/icons/lock';
import Logout from 'react-feather/dist/icons/log-out';
import Mail from 'react-feather/dist/icons/mail';
import Map from 'react-feather/dist/icons/map';
import MapPin from 'react-feather/dist/icons/map-pin';
import Maximize2 from 'react-feather/dist/icons/maximize-2';
import Meh from 'react-feather/dist/icons/meh';
import Menu from 'react-feather/dist/icons/menu';
import MessageCircle from 'react-feather/dist/icons/message-circle';
import Minus from 'react-feather/dist/icons/minus';
import MinusCircle from 'react-feather/dist/icons/minus-circle';
import Monitor from 'react-feather/dist/icons/monitor';
import MoreVertical from 'react-feather/dist/icons/more-vertical';
import MoreHorizontal from 'react-feather/dist/icons/more-horizontal';
import Moon from 'react-feather/dist/icons/moon';
import Music from 'react-feather/dist/icons/music';
import Navigation from 'react-feather/dist/icons/navigation';
import Paperclip from 'react-feather/dist/icons/paperclip';
import PauseCircle from 'react-feather/dist/icons/pause-circle';
import PenTool from 'react-feather/dist/icons/pen-tool';
import Phone from 'react-feather/dist/icons/phone';
import Play from 'react-feather/dist/icons/play';
import Plus from 'react-feather/dist/icons/plus';
import PlusCircle from 'react-feather/dist/icons/plus-circle';
import PlusSquare from 'react-feather/dist/icons/plus-square';
import Printer from 'react-feather/dist/icons/printer';
import Radio from 'react-feather/dist/icons/radio';
import Refresh from 'react-feather/dist/icons/refresh-ccw';
import Repeat from 'react-feather/dist/icons/repeat';
import RotateCcw from 'react-feather/dist/icons/rotate-ccw';
import RotateCw from 'react-feather/dist/icons/rotate-cw';
import Save from 'react-feather/dist/icons/save';
import Search from 'react-feather/dist/icons/search';
import Server from 'react-feather/dist/icons/server';
import ShoppingBag from 'react-feather/dist/icons/shopping-bag';
import ShoppingCart from 'react-feather/dist/icons/shopping-cart';
import Settings from 'react-feather/dist/icons/settings';
import Shuffle from 'react-feather/dist/icons/shuffle';
import Slash from 'react-feather/dist/icons/slash';
import Sliders from 'react-feather/dist/icons/sliders';
import Smartphone from 'react-feather/dist/icons/smartphone';
import Smile from 'react-feather/dist/icons/smile';
import Square from 'react-feather/dist/icons/square';
import Star from 'react-feather/dist/icons/star';
import Shield from 'react-feather/dist/icons/shield';
import ThumbsDown from 'react-feather/dist/icons/thumbs-down';
import ThumbsUp from 'react-feather/dist/icons/thumbs-up';
import Tag from 'react-feather/dist/icons/tag';
import Tool from 'react-feather/dist/icons/tool';
import Trash from 'react-feather/dist/icons/trash';
import TrendingDown from 'react-feather/dist/icons/trending-down';
import TrendingUp from 'react-feather/dist/icons/trending-up';
import Truck from 'react-feather/dist/icons/truck';
import Type from 'react-feather/dist/icons/type';
import TV from 'react-feather/dist/icons/tv'
import Unlock from 'react-feather/dist/icons/unlock';
import UploadCloud from 'react-feather/dist/icons/upload-cloud';
import User from 'react-feather/dist/icons/user';
import UserCheck from 'react-feather/dist/icons/user-check';
import UserPlus from 'react-feather/dist/icons/user-plus';
import Users from 'react-feather/dist/icons/users';
import Video from 'react-feather/dist/icons/video';
import Volume2 from 'react-feather/dist/icons/volume-2';
import VolumeX from 'react-feather/dist/icons/volume-x';
import Watch from 'react-feather/dist/icons/watch';
import X from 'react-feather/dist/icons/x';
import XCircle from 'react-feather/dist/icons/x-circle';
import Zap from 'react-feather/dist/icons/zap';
import ZoomIn from 'react-feather/dist/icons/zoom-in';
import ZoomOut from 'react-feather/dist/icons/zoom-out';

export {
  Activity,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Archive,
  ArrowDownRight,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUpRight,
  Award,
  BatteryCharging,
  Bell,
  BellOff,
  BookOpen,
  Calendar,
  Camera,
  CameraOff,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  Code,
  Coffee,
  Copy,
  CornerDownRight,
  Crop,
  Crosshair,
  Download,
  DollarSign,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  FastForward,
  Feather,
  File,
  FilePlus,
  FileText,
  Film,
  Filter,
  Folder,
  Flag,
  Gift,
  Globe,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Home,
  Image,
  Info,
  Layers,
  Layout,
  List,
  Loader,
  Lock,
  Logout,
  Mail,
  Map,
  MapPin,
  Maximize2,
  Meh,
  Menu,
  MessageCircle,
  Minus,
  MinusCircle,
  Monitor,
  MoreVertical,
  MoreHorizontal,
  Moon,
  Music,
  Navigation,
  Paperclip,
  PauseCircle,
  PenTool,
  Phone,
  Play,
  Plus,
  PlusCircle,
  PlusSquare,
  Printer,
  Radio,
  Refresh,
  Repeat,
  RotateCcw,
  RotateCw,
  Save,
  Search,
  Server,
  ShoppingBag,
  ShoppingCart,
  Settings,
  Shuffle,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Square,
  Star,
  Shield,
  ThumbsDown,
  ThumbsUp,
  Tag,
  Tool,
  Trash,
  TrendingDown,
  TrendingUp,
  Truck,
  Type,
  TV,
  Unlock,
  UploadCloud,
  User,
  UserCheck,
  UserPlus,
  Users,
  Video,
  Volume2,
  VolumeX,
  Watch,
  X,
  XCircle,
  Zap,
  ZoomIn,
  ZoomOut,
};
